import { Component } from '@angular/core'
import { AppModeService } from '../../app-mode.service'

@Component({
    template: `
    <div id="main-content">
    <table class="mission-statement-table">
        <tr>
            <td class="mission-statement-column1">
            </td>  
        </tr>
        <td class="mission-statement-column2">
                <h2><strong>Langugage Corner</strong></h2>
                <div class="author-date-item">Jagan Lakshmipathy</div>
                <div class="author-date-item">January 6, 2020</div>
                <p></p>
                <p> What Computer languages are for Computer Scientists is what human laguages are for linguists. Computer languages enable computer scientists and engineers to be efficient and expressive. Here we will focus on languages that are popular in the Big Data world. Lets begin with Java (more specifically Java 8+) and scala. Here we will not do a puristic comparison of these two languages. Instead we will pick some interesting computer science problems and try solve them in both languages and compare the approaches. That way we will be able to understand the APIs in both languages and can get a good grasp.</p>
                <p>Following are some problems we will attempt to solve in both Java and Scala and compare them side by side:</p>
                <ol class="sublink-refs">
                    <li><a [routerLink] = "['/lt1']">Compute all subsets of a give set</a></li>
                    <li><a [routerLink] = "['/lt2']">Compute subsets of size <i>n</i> for a given set of size <i>m >= n</i></a></li>
                    <li><a [routerLink] = "['/lt3']">Compute disjoint subsets for a given set</a></li>
                </ol>
            </table>


    `
})
export class scalaComponent {
   
    constructor(private modeService: AppModeService){
        this.modeService.displaySidebar()
    }
}