import { Component } from '@angular/core'
import { AppModeService } from '../../app-mode.service'

@Component({
    template: `
    <div id="main-content">
        <table class="mission-statement-table">
            <tr>
                <td class="mission-statement-column1">
                </td>           
                <td class="mission-statement-column2">
                <h2><strong>Install Hadoop (Single Node Setup), and Spark </strong></h2>
                <div class="author-date-item">Jagan Lakshmipathy</div>
                <div class="author-date-item">September 28, 2019</div>
                <p></p>
                <p>I was determined to start my Data Science and Machine Learning Journey and I was not sure where to start. If you were like me, you have to overcome this feeling and get started somewhere. I am writing this page to benefit those overwhelmed readers who want to get started on this journey. There are lot of sites in the internet talking about pros and cons about Spark and Hadoop technologies and even compare them about its superiority over the other. In my opinion they are two complementary technologies and we don’t need to make a choice to pick one over the other. In fact, Spark was developed with an intent to exist in the Hadoop Ecosystem. For beginners it is helpful to play with both of these technologies to understand the concepts. Moreover, we need to play with them on your laptop before you could deploy them in a cloud environment like AWS, GCP, etc. So, go ahead and install these software on your platform of choice (windows, linux, mac os, etc.). I started this on my new macbook air I purchased recently. This is the link where I started(<A href="https://gist.github.com/cjzamora/9fcc740228875f4642a1">link1</A>). </p>
                <p>The above link provides 6 steps to install Hadoop and Spark. As Spark's native language, Scala is a good to have in your system. So, let's go ahead and install it too. Lets get started. As I would like to use HomeBrew to install software in my macos laptop, I first installed brew by running the following commands.</p>
                <pre>$> /usr/bin/ruby -e "$(curl -fsSL https://raw.githubusercontent.com/Homebrew/install/master/install)"
$> brew tap caskroom/versions
$> brew update</pre>
                <p>As JDK is foundational to all of these technologies, we have to install JDK. To find out what version of JDK to install, I ran the following the command.</p> 
                <pre>
$> brew info hadoop</pre>
                <p>HomeBrew informed me JDK8 is mandatory for Hadoop 3.1.2 (which is the latest at the time of writing this page). So, I installed JDK 8 as per this link (<A href="https://stackoverflow.com/questions/24342886/how-to-install-java-8-on-mac">link2</A>). Note oracle version is not available anymore, so I have to stick with OpenJDK. Following are the commands to install OpenJDK8:</p>
                <pre>$> brew tap adoptopenjdk/openjdk
$> brew cask install adoptopenjdk/openjdk/adoptopenjdk8</pre>
                <p>After having JDK installed, now we are ready to follow the 6 steps outlined in link1. As per step 1, we are supposed to verify with brew if all latest versions of hadoop, spark, scala, and sbt  are compatible with each other. We do this by running the following commands. Output of these commands will show a RED cross mark if brew finds anything incompatible.</p>
                <pre>
$> brew info hadoop
$> brew info pache-spark
$> brew info scala
$> brew info sbt</pre>
                <p>Now we are in step 2. We are ready to install hadoop, spark, scala and sbt in that order. Following are the commands.</p>
                <pre>
$> brew install hadoop
$> brew install apache-spark scala sbt</pre>
<p>At step 3, we will are ready to configure the environment variables in our shell profile. I am using bash shell and so I edited my .bash-profile file to add the following environment variables, path variables, and alias</p>
                <pre>
# set environment variables
export JAVA_HOME=$(/usr/libexec/java_home)
export HADOOP_HOME=/usr/local/Cellar/hadoop/3.1.2
export HADOOP_CONF_DIR=$HADOOP_HOME/libexec/etc/hadoop
export HADOOP_INSTALL=$HADOOP_HOME
export HADOOP_MAPRED_HOME=$HADOOP_INSTALL
export HADOOP_COMMON_HOME=$HADOOP_INSTALL
export HADOOP_HDFS_HOME=$HADOOP_INSTALL
export YARN_HOME=$HADOOP_INSTALL
export SCALA_HOME=/usr/local/Cellar/apache-spark/2.4.4
                
# set path variables
export PATH=$PATH:$HADOOP_HOME/bin:$SCALA_HOME/bin
                
# set alias start & stop scripts
alias hstart=$HADOOP_HOME/sbin/start-dfs.sh;$HADOOP_HOME/sbin/start-yarn.sh
alias hstop=$HADOOP_HOME/sbin/stop-dfs.sh;$HADOOP_HOME/sbin/stop-yarn.sh</pre>
<p>Now we have configured the ssh on localhost and check the remote login without passphrase. In Mac, I have to enable remote login in "system prefrences" menu. This step may differ for different platforms. The following are the commands to enable SSH in mac.</p>
<pre>
$> ssh-keygen -t rsa
(Press enter for each line) 
$> cat ~/.ssh/id_rsa.pub >> ~/.ssh/authorized_keys
$> chmod og-wx ~/.ssh/authorized_keys </pre>
<p>At step 4, we are ready to configure Handoop. Let's cd to $HADOOP_HOME/libexec/etc/hadoop and edit hadoop-env.sh such that it exports HADOOP_OPTS with the following value. </p>
<pre> export HADOOP_OPTS="$HADOOP_OPTS -Djava.security.krb5.realm= -Djava.security.krb5.kdc="</pre>

<p>Now edit files core-site.xml, hdfs-site.xml, mapred-site.xml, and yarn-site.xml under the same directory $HADOOP_HOME/libexec/hadoop/ as follows</p>
<pre>Edit ‘etc/hadoop/core-site.xml’:

&lt;configuration&gt;
  &lt;property&gt;
    &lt;name&gt;fs.defaultFS&lt;name&gt;
    &lt;value&gt;hdfs://localhost:9000&lt;/value&gt;
  &lt;/property&gt;
&lt;/configuration&gt;

Edit ‘etc/hadoop/hdfs-site.xml’:

&lt;configuration&gt;
  &lt;property&gt;
    &lt;name&gt;dfs.replication&lt;name&gt;
    &lt;value&gt;1&lt;/value&gt;
  &lt;/property&gt;
&lt;/configuration&gt;

Edit ‘etc/hadoop/mapred-site.xml’:

&lt;configuration&gt;
  &lt;property&gt;
    &lt;name&gt;mapreduce.framework.name&lt;name&gt;
    &lt;value&gt;yarn&lt;/value&gt;
  &lt;/property&gt;
&lt;/configuration&gt;

Edit ‘etc/hadoop/yarn-site.xml’:

&lt;configuration&gt;
  &lt;property&gt;
    &lt;name&gt;yarn.nodemanager.aux-services&lt;name&gt;
    &lt;value&gt;mapreduce_shuffle&lt;/value&gt;
  &lt;/property&gt;
&lt;/configuration&gt;
</pre>
<p>Now that Hadoop is configured, I tried running hadoop as mentioned in step 5. </p>
<pre>$>cd $HADOOP_HOME
$>./bin/hdfs namenode -format 
$>./sbin/start-dfs.sh
$>./sbin/start-yarn.sh</pre>
<p>At this time I noticed this command didn't start hadoop. I realized that I have to sudo as root (as Brew had installed Hadoop under /user/local/Cellar/). I tried again with sudo. This time I saw some syntax errors when running the script. when I googled I found out that this was a known bug in 3.1.2 version. So I switched to next lower version of hadoop i.e. 2.9.2. This time I fetched the .tar.gz for this version and expanded it in my home directory (to avoid installing it under /usr/local).</p>
<p>Now I tweaked my bash-profile to point to the downloaded hadoop 2.9.2 and it now looked as follows</p>
<pre>
# set environment variables
export JAVA_HOME=$(/usr/libexec/java_home)
export HADOOP_HOME=$HOME/sofware/hadoop-2.9.2
export HADOOP_CONF_DIR=$HADOOP_HOME/libexec/etc/hadoop
export HADOOP_INSTALL=$HADOOP_HOME
export HADOOP_MAPRED_HOME=$HADOOP_INSTALL
export HADOOP_COMMON_HOME=$HADOOP_INSTALL
export HADOOP_HDFS_HOME=$HADOOP_INSTALL
export YARN_HOME=$HADOOP_INSTALL
export SCALA_HOME=/usr/local/Cellar/apache-spark/2.4.4
                
# set path variables
export PATH=$PATH:$HADOOP_HOME/bin:$SCALA_HOME/bin
                
# set alias start & stop scripts
alias hstart=$HADOOP_HOME/sbin/start-dfs.sh;$HADOOP_HOME/sbin/start-yarn.sh
alias hstop=$HADOOP_HOME/sbin/stop-dfs.sh;$HADOOP_HOME/sbin/stop-yarn.sh</pre>

<p>Again, I restared Hadoop. This time I saw the HDFS command worked as expected but Hadoop didn't start. I saw some exceptions due to malformed url in hdfs-site.xml and core-site.xml. After tweaking the hadoop resource files and restarted Hadoop and it worked as expected. I have uploaded my hadoop configs in <a href="https://github.com/jagan-lakshmipathy/Hadoop-Spark-install">git</a> for your convenience to download and try.</p>

<p>We are at Step 6. We are ready to start using Spark. We tried the following things as outlined in step 6 and everything worked as expected.</p>
<pre>$>cd /usr/local/Cellar/apache-spark/1.1.0
# use the spark shell (local with 1 thread)
$>./bin/spark-shell
spark-shell>println("Hello, World!")
spark-shell>val a = 5
spark-shell>a + 3
spark-shell>sc.parallelize(1 to 1000).count()
spark-shell>exit</pre>
<p>If you are successful up to this point then Congratulations!!!. You have sucessfully installed Hadoop, and spark. I went ahead and verified the installations by running some Hadoop examples and Scala examples to test the installation. Everything worked as expected.</p>


                </td>
                <td class="mission-statement-column3">
                </td>  
            </tr>
        </table>

    </div>
    `
})
export class InstallHadoopAndSparkComponent {
   
    constructor(private modeService: AppModeService){
        this.modeService.displaySidebar()
    }
}