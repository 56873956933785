import { Component } from '@angular/core'
import { AppModeService } from '../../app-mode.service'

@Component({
    template: `
    <div id="main-content">
    <table class="mission-statement-table">
        <tr>
            <td class="mission-statement-column1">
            </td>  
        </tr>
        <td class="mission-statement-column2">
                <h2><strong>Ridge vs. Lasso</strong></h2>
                <div class="author-date-item">Jagan Lakshmipathy</div>
                <div class="author-date-item">January 25, 2020</div>
                <p>These posts <a href="https://www.analyticsvidhya.com/blog/2016/01/complete-tutorial-ridge-lasso-regression-python/"> link1, </a>  and <a href="http://wavedatalab.github.io/machinelearningwithr/post4.html"> link2 </a>cover good ground on Ridge and Lasso.</p>
                <p></p>
            </table>


    `
})
export class ridgevslassoComponent {
   
    constructor(private modeService: AppModeService){
        this.modeService.displaySidebar()
    }
}