import { NgModule } from '@angular/core'
import { Routes, RouterModule} from '@angular/router'
import { HomeComponent } from './core/home/home.component'
import { AboutComponent } from './About/about.component'
import { SoftwareComponent } from './core/software/software.component'
import { InstallHadoopAndSparkComponent } from './categories/InstallHadoopAndSpark/installhadoopandspark.component'
import { InstallAndRunKafkaComponent } from './categories/InstallAndRunKafka/installandrunkafka.component'
import { nnWithRegComponent } from './categories/nnWithReg/nnWithReg.component'
import { nnWithoutRegComponent } from './categories/nnWithoutReg/nnWithoutReg.component'
import { peopleBioComponent } from './People/people.component'
import { scalaComponent} from './categories/Scala/Scala.componenent'
import { linearregComponent} from './categories/scikitlearn/linearreg.componenent'
import { ridgevslassoComponent} from './categories/scikitlearn/ridgevslasso.component'
import { languageTopicComponent1} from './categories/Scala/languagetopic1.component'
import { languageTopicComponent2} from './categories/Scala/languagetopic2.component'
import { languageTopicComponent3} from './categories/Scala/languagetopic3.component'

 


import { RunWordcountComponent } from './categories/RunHadoopWordcountExample/RunHadoopWordcountExample.component'
import { SparkSqlComponent } from './categories/SparSql/SparkSql.component'
import { StructuredStreamingComponent } from './categories/StructuredStreaming/StructuredStreaming.component'

const routes: Routes = [{path: 'home', component: HomeComponent},
                        {path: 'about', component: AboutComponent},
                        {path: 'software', component: SoftwareComponent},
                        {path: 'installhadoopsparkcompoent', component: InstallHadoopAndSparkComponent},
                        {path: 'installandrunkafka', component: InstallAndRunKafkaComponent},
                        {path: 'nnwithoutreg', component: nnWithoutRegComponent},
                        {path: 'nnwithreg', component: nnWithRegComponent},
                        {path: 'scala', component: scalaComponent},
                        {path: 'linearreg', component: linearregComponent},
                        {path: 'ridgevslasso', component: ridgevslassoComponent},
                        {path: 'lt1', component: languageTopicComponent1},
                        {path: 'lt2', component: languageTopicComponent2},
                        {path: 'lt3', component: languageTopicComponent3},
                        {path: 'RunWordcountComponent', component: RunWordcountComponent},
                        {path: 'SparkSqlComponent', component: SparkSqlComponent},
                        {path: 'StructuredStreamingComponent', component: StructuredStreamingComponent},
                        {path: 'people', component: peopleBioComponent},
                        {path: '**', component: HomeComponent}]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {

}