import { Component, OnChanges } from '@angular/core'
import { AppModeService } from '../app-mode.service'

@Component({
    template: `
    <div id="main-content">
        <table class="mission-statement-table">
            <tr>
                <td class="mission-statement-column1">
                </td>           
                <td class="mission-statement-column2">
                <h1 align="center"> About Us </h1>
                    <div class="thb-text">
                        <p>We are a team of technology evangelists who feel strongly about open source community. As empowerment is deeply ingrained in our mission, we are extremely motivated and dedicated to help our clients deliver Resilient, and Responsive applications. After all our success is rested on your success.</p>
                        <p>The comprehensive Lightbend platform offers suite of products not limited to (a) Reactive Microservices, (b) Data Streaming, and (c) DevOp. All of this come with Lightbend promise. </p>
                        <p>As leaders in Lightbend ecosystem, we intend to provide application development, consulting and training. </p>
                        <br>
                        <br>
                        </div>
                </td>
                <td class="mission-statement-column3">
                </td>  
            </tr>
        </table>

    </div>
    `
})
export class AboutComponent {
    constructor(private modeService: AppModeService){
        this.modeService.hideSidebar()
    }
}