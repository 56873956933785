import { Component } from '@angular/core'

@Component({
    selector: 'app-header',
    template : `
    <div class = "hdr-bg-img">
        <nav class="navbar navbar-expand-lg navbar-light" style="background-color: #f0f0f0;">
            <a class="navbar-brand text-primary" href="#"> <img style="height: 50px; width: auto;" 
                    src="../../../assets/images/asymp.jpg"></a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                <li class="nav-item active">
                    <a class="nav-link text-primary" [routerLink] = "['/home']">Mission <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-primary" [routerLink] = "['/software']">Software</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link text-primary dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    About Us
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item text-primary" href="/about">Our Services</a>
                    <a class="dropdown-item text-primary" [routerLink] = "['/people']">People</a>

                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item text-primary" href="#" class="disabled">Contact Us</a>
                    </div>
                </li>

                </ul>
                <form class="form-inline my-2 my-lg-0">
                <input class="form-control text-primary mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                <button class="btn btn-outline-primary my-2 my-sm-0" type="submit">Search</button>
                </form>
            </div>
        </nav>
    </div>
    `
})
export class HeaderComponent {

}
