import { Component, OnChanges } from '@angular/core'
import { AppModeService } from '../../app-mode.service'

@Component({
    template: `
    <div id="main-content">
        <table class="mission-statement-table">
            <tr>
                <td class="mission-statement-column1">
                </td>           
                <td class="mission-statement-column2">
                <h1 align="center"> Mission Statement </h1>
                    <div class="thb-text">
                       <p>We dream to create a world that provides equal opportunity for all. Every step towards this goal is significant in itself.</p>
                        <p>We all know that our today's decisions are the foundations of our tomorrow's outcome. In today's world we are surrounded by so much information. We have not only submerged in it but also allowed it to cloud our perception. And the very data we have collected, thinking it is our weapon to craft our destiny, has now become our liability. So, in order to emerge out with clarity from this pile of information we have to innovate, develop and harness tools that auto learn and adapt to de-clutter the information we have submerged ourselves in and guide us to clarity.</p>
                        <p>We, at Asymptoid Analytics Lab, are focused on developing analytical capabilities to enhance our decision. We not only innovate and develop these tools but also will make it accessible to all, so that companies, big or small, and individuals, powerful or not, are able to make decisions and there by able to craft the destiny of their own. We will be with you in every step of his journey of transformation and see you succeed. We believe this empowerment will make this world truly a competitive, equitable and sustainable one. </p> 
                        <p>Our small step towards this goal is significant in itself.</p>
                        <br>
                        <br>
                        <h6 align="center"> Naeem Holman: "[Prejudice] is belittling because [prejudiced] don’t see you as their equal."</h6>
                    </div>
                </td>
                <td class="mission-statement-column3">
                </td>  
            </tr>
        </table>

    </div>
    `
})
export class HomeComponent {
    constructor(private modeService: AppModeService){
        this.modeService.hideSidebar()
    }
}