import { Component } from '@angular/core'
import { AppModeService } from '../../app-mode.service'

@Component({
    template: `
    <div id="main-content">
    <table class="mission-statement-table">
        <tr>
            <td class="mission-statement-column1">
            </td>  
        </tr>
        <td class="mission-statement-column2">
                <h2><strong>Linear Regression Algorithms</strong></h2>
                <div class="author-date-item">Jagan Lakshmipathy</div>
                <div class="author-date-item">January 25, 2020</div>
                <p></p>
                <p>I am overwhelmed by the number of linear regression implmenations that Scikit-learn provides off the shelf. Most of the time I don't know where to begin. So I was reading about them one after the other. I stumbled upon this  <a href="https://towardsdatascience.com/choosing-a-scikit-learn-linear-regression-algorithm-dd96b48105f5"> link </a>which served as a good starting point. The following table lists the linear regressors we have analyzed here. I will keep growing this list as we explore more.</p>
                <pre class="pre-code-area"><table border="1">
                    <tr><th f>#</th> <th>Model Name</th> <th> Description</th></tr>
                    <tr><td>1</td> <td>Linear Regression</td> <td> Ordinary Least Square (OLS) Algorithm</td></tr>
                    <tr><td>2</td> <td>Ridge</td> <td> OLS with added L2 regularization</td></tr>
                    <tr><td>3</td> <td>Lasso</td> <td> OLS with added L1 regularization</td></tr>
                    <tr><td>4</td> <td>ElasticNet</td> <td> OLS with both L1 and L2 regularization</td></tr>
                </table></pre>
                <p>Following are some problems we will attempt to solve in both Java and Scala and :</p>
                <ol class="sublink-refs">
                    <li><a [routerLink] = "['/ridgevslasso']" class="disabled">Ridge vs. Lasso</a></li>
                </ol>
            </table>


    `
})
export class linearregComponent {
   
    constructor(private modeService: AppModeService){
        this.modeService.displaySidebar()
    }
}