import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class AppModeService{
    public showSidebar: boolean

    displaySidebar(){
        this.showSidebar = true
    }

    hideSidebar(){
        this.showSidebar = false
    }
}