import { Component } from '@angular/core'
import { AppModeService } from '../../app-mode.service'

@Component({
    template: `
    <div id="main-content">
    <table class="mission-statement-table">
        <tr>
            <td class="mission-statement-column1">
            </td>  
        </tr>
        <td class="mission-statement-column2">
                <h2><strong>n-Neural Network with Regularization </strong></h2>
                <div class="author-date-item">Jagan Lakshmipathy</div>
                <div class="author-date-item">December 6, 2019</div>
                <p></p>
                <p>In this section we will take the code from our non-regularized version in our previous section and modify it. We will use the same MNIST dataset. Theory behind regularization is well documented and we can see detailed explanation in Andrew's page here and in this Aurelien Geron's book. Following are the exprssions for cost C and increment for W.</p>
                <pre> C = <sup>1</sup>&frasl;<sub>2*m</sub> . <font size="6">[</font> <font size="5">&#8721;</font>C<sub>i</sub>(W) + &#8907;.<font size="5">&#8721;</font>W<sub>j</sub><sup>2</sup><font size="6">]</font>&nbsp; &nbsp; &nbsp; &nbsp;(1)
  &#916;W = <sup>1</sup>&frasl;<sub>m</sub> . <font size="6">[</font> <font size="5">&#8711;</font>W + &#8907;.<font size="5">&#8721;</font>W<sub>j</sub><font size="6">]</font>&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;(2)</pre>

    Followiing function computes the regularizaion component (second term) in equation 1. 
<pre class="pre-code-area">def compute_regularization_component_for_cost(self):
  l2_reg = 0
  for l in range (1, self.L + 1):         
    l2_reg += np.sum(np.square(self.parameters['W' + str(l)]))
return l2_reg</pre>
    Similarly, the following function implements the regularization correction for W at each layer of the network.
<pre class="pre-code-area">def update_parameters2(self, alpha, lmb):
  for l in range(1, self.L+1):
    self.parameters['W' + str(l)] += alpha*lmb*self.parameters['W' + str(l)]</pre>
    Finally, at the end of each iteration we update the W, and C as follows:
<pre class="pre-code-area">l2 = self.compute_regularization_component_for_cost()
self.update_parameters2(alpha, lmb)
print("Cost: ", (c + lmb*l2)/x.shape[0])</pre>
The following table summarizes the accuracy of the training. At the end of 20<sup>th</sup> iteration the model can predict the training data 98.2% of the times correctly. When we tried to predict the test data with that same model we were able to predict 96.5% of the times correctly. 
<pre class="pre-code-area"><table border="1">
<tr>
  <td>&nbsp; &nbsp; # &nbsp; &nbsp; &nbsp; &nbsp; </td>
  <td>&nbsp; &nbsp;02 &nbsp;</td>
  <td>&nbsp; &nbsp;04 &nbsp;</td>
  <td>&nbsp; &nbsp;06 &nbsp;</td>
  <td>&nbsp; &nbsp;08 &nbsp;</td>
  <td>&nbsp; &nbsp;10 &nbsp;</td>
  <td>&nbsp; &nbsp;12 &nbsp;</td>
  <td>&nbsp; &nbsp;14 &nbsp;</td>
  <td>&nbsp; &nbsp;16 &nbsp;</td>
  <td>&nbsp; &nbsp;18 &nbsp;</td>
  <td>&nbsp; &nbsp;20 &nbsp;</td>
</tr>
<tr>
<td>&nbsp; &nbsp; % acc.</td>
<td>&nbsp; &nbsp;11.9</td>
<td>&nbsp; &nbsp;15.4</td>
<td>&nbsp; &nbsp;45.6</td>
<td>&nbsp; &nbsp;93.2</td>
<td>&nbsp; &nbsp;96.2</td>
<td>&nbsp; &nbsp;97.2</td>
<td>&nbsp; &nbsp;97.7</td>
<td>&nbsp; &nbsp;97.9</td>
<td>&nbsp; &nbsp;98.1</td>
<td>&nbsp; &nbsp;98.2</td>
</tr>

</table></pre>

As the following table summarizes, our trained model failed 10 times when predicting class "0". Similarly, the model failed 14 times when predicting class "1" and so on. Looks like our model failed most when predicting class "9".
<br />
<pre class="pre-code-area"><table border="1">
<tr>
  <td>&nbsp; &nbsp; class &nbsp; &nbsp; &nbsp; &nbsp; </td>
  <td>&nbsp; &nbsp;0 &nbsp;</td>
  <td>&nbsp; &nbsp;1 &nbsp;</td>
  <td>&nbsp; &nbsp;2 &nbsp;</td>
  <td>&nbsp; &nbsp;3 &nbsp;</td>
  <td>&nbsp; &nbsp;4 &nbsp;</td>
  <td>&nbsp; &nbsp;5 &nbsp;</td>
  <td>&nbsp; &nbsp;6 &nbsp;</td>
  <td>&nbsp; &nbsp;7 &nbsp;</td>
  <td>&nbsp; &nbsp;8  &nbsp;</td>
  <td>&nbsp; &nbsp;9 &nbsp;</td>
</tr>
<tr>
<td>&nbsp; &nbsp; # failed pred.</td>
<td>&nbsp; &nbsp;10</td>
<td>&nbsp; &nbsp;14</td>
<td>&nbsp; &nbsp;41</td>
<td>&nbsp; &nbsp;34</td>
<td>&nbsp; &nbsp;39</td>
<td>&nbsp; &nbsp;39</td>
<td>&nbsp; &nbsp;30</td>
<td>&nbsp; &nbsp;40</td>
<td>&nbsp; &nbsp;42</td>
<td>&nbsp; &nbsp;57</td>
</tr>

</table></pre>
</table>

    `,
    styleUrls:['./nnWithReg.component.css']
})
export class nnWithRegComponent {
   
    constructor(private modeService: AppModeService){
        this.modeService.displaySidebar()
    }
}