import { Component } from '@angular/core'
import { AppModeService } from '../app-mode.service'

@Component({
    template: `
    <div id="main-content">
    <table class="mission-statement-table">
        <tr class="mission-statement-column1">
        
            <td>
                <section class="first odd">
                    <span>
                        <img class="right" src="../../assets/images/jagan.jpeg" style="height: 300px; width: auto;"  title="Gravatar of Bill Bejeck">
                    </span>
                    <br>
                </section>
            </td>
            <td>
                    <span> 
                        I am Jagan, Lakshmipathy, Technology Evangelist in Asymptoid Analytics. I am a technology lead, a software developer and an software 
                        architect all in one. I started my career developing 3D graphics algorithms and systems in C/C++. I have developed systems in telphony 
                        and finance. I am currently focused in solving big data challenges, reactive architectures, and machine learning algorithms. 
                        I enjoy functional programming, developing highly performant code in batch processing systems like Spark, Hadoop, etc and scale 
                        them by running them in commercial cloud platforms like (GCE, AWS, etc.). I enjoy optimizing data queries in both SQL and noSQL systems 
                        (e.g. Hive, Spark Sql, etc.). Though I enjoy coding in Java, python and Scala, I am very bullish about Scala and its future. I also enjoy 
                        outdoor and travel.
                    </span>
            </td>
        </tr>
    </table>
    <h5>Jagan Lakshmipathy</h5>
    <h6>CIO and Technology Evagelist</h6>
    `
})
export class peopleBioComponent {
   
    constructor(private modeService: AppModeService){
        this.modeService.displaySidebar()
    }
}