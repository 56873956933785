import { Component, OnInit } from '@angular/core'
import { AppModeService } from '../../app-mode.service'

@Component({
    selector :'app-rightsidebar',
    template : `
    <div *ngIf="showbar()">
        <h3 class= "right-title"> Categories: </h3>
        
        <h4 class="right-category">Infrastructure</h4>
        <p class="right-category-item" ><a [routerLink] = "['/installhadoopsparkcompoent']">Install Hadoop, and Spark</a></p>
        <p class="right-category-item" ><a [routerLink] = "['/installandrunkafka']">Install and run Kafka</a></p>

        <h4 class="right-category">Machine Learning</h4>
        <h5 class="right-sub-category">n-Neural Network (python)</h5>
        <p class="right-category-item" ><a [routerLink] = "['/nnwithoutreg']">Implementation without Regularization</a></p>
        <p class="right-category-item" ><a [routerLink] = "['/nnwithreg']">Implementation with Regularization</a></p>
        <h5 class="right-sub-category">Scikit-learn</h5>
        <p class="right-category-item" ><a [routerLink] = "['/linearreg']">Linear Regression Algorithms</a></p>
        

        <h4 class="right-category">Technologies</h4>
        <h5 class="right-sub-category">Spark</h5>
        <p class="right-sub-sub-category">Concepts</p>
        <p class="right-category-item" ><a [routerLink] = "['/StructuredStreamingComponent']">Structured Streaming</a></p>
        <p class="right-category-item" ><a [routerLink] = "['/SparkSqlComponent']">Spark SQL</a></p>
        <p class="right-sub-sub-category">Algorithms</p>
        <p class="right-category-item" ><a href="#" class="disabled">K-means Clustering</a></p>
        <p class="right-category-item" ><a href="#" class="disabled">Transitive Closure</a></p>
        <h5 class="right-sub-category">Hadoop</h5>
        <p class="right-sub-sub-category">Concepts</p>
        <p class="right-category-item" ><a href="#" class="disabled">Map/Reduce (MR)</a></p>
        <p class="right-sub-sub-category">Algorithms</p>
        <p class="right-category-item" ><a [routerLink] = "['/RunWordcountComponent']">Word Count Example</a></p>

        <h4 class="right-category"><a [routerLink] = "['/scala']">Language Corner</a></h4>
        <p class="right-category-item" ><a [routerLink] = "['/lt1']">All subsets of a set</a></p>
        <p class="right-category-item" ><a [routerLink] = "['/lt2']">Subsets of size <i>k</i> for a given set</a></p>
        <p class="right-category-item" ><a [routerLink] = "['/lt3']" class="disabled">Disjoint subsets of a set</a></p>
    </div>
    `
})

export class RightSidebar implements OnInit{
    
  isHidden: Boolean = true

  constructor(private modeService: AppModeService){
  }

  showbar(){
    console.log("S")
    return this.modeService.showSidebar
  }

  ngOnInit(){
    console.log('E1')
  }

}