import { Component } from '@angular/core'
import { AppModeService } from '../../app-mode.service'

@Component({
    template: `
    <div id="main-content">
    <table class="mission-statement-table">
        <tr>
            <td class="mission-statement-column1">
            </td>  
        </tr>
        <td class="mission-statement-column2">
                <h2><strong>Compute all subsets of a given set</strong></h2>
                <div class="author-date-item">Jagan Lakshmipathy</div>
                <div class="author-date-item">January 6, 2020</div>
                <p></p>
                <p> The following scala snippet implements a class SubsetGenerator with a function <i class="code-ref-area"> subsetIterator()</i>. <i class="code-ref-area"> subsetIterator()</i> generates subsets of all sizes.</p>
                <pre class="pre-code-area">class SubsetGenerator&#123;<br />  def subsetIterator(l:List[Int]) : List[Set[Int]] = &#123;<br />    if (l.length &lt;= 0)<br />      return List[Set[Int]]()<br />    var pr: List[Set[Int]] = List(Set(), Set(l(0)))<br />    l.tail.foreach(n =&gt; &#123;<br />      pr = pr.collect(s =&gt; List(s, s.incl(n))).flatten<br />    &#125;)<br />    return pr<br />  &#125;<br />&#125;<br />object SubsetGenerator&#123;<br />  def main(args:Array[String]):Unit = &#123;<br />    val ssg = new SubsetGenerator()<br/>    val results = ssg.subsetIterator(List(1, 2, 3, 4))<br />    println(results)<br />  &#125;<br />&#125;<br /><br /></pre>   
                <p>Function <i class="code-ref-area">subsetIterator()</i> in the above scala code takes one input <i>l</i> of type <i class="code-ref-area">List(Int)</i>. This function will return a <i class="code-ref-area"> List(Set(Int)) </i>where each set with in this list refers to a subset of elements in <i>l </i>. We first check the length of the input list. If the length is 0 then there is nothing to do. We just return an empty list. Else, we create a partial list with two sets, an empty set and a set out of first element in <i>l</i>, in <i>pr</i>. We then iterate for each integer element <i>i</i> in tail of <i>l</i>. At each iteration we replace each set <i>s</i> in <i>pr</i> with a list <i>l<sub>i</sub></i> of type <i class="code-ref-area">List(Set(int))</i> and add <i>s</i> and <i>s + i</i> to <i>l<sub>i</sub></i>. We then flatten this List(List(Set(Int))) to List(Set(Int)) and assign it back to <i>pr</i>. At the end of this iteration <i>pr</i> will have subsets for the provided input <i>l</i>. Now let's turn our attention to implement the same function in java.</p>
                <pre class="pre-code-area">import java.lang.reflect.Array;<br /><br />import java.util.*;<br />import java.util.Set;<br />import java.util.function.Function;<br />import java.util.stream.Collectors;<br />import java.util.stream.Stream;<br /><br /><br />public class ComuteList &#123;<br />    private List&lt;Set&lt;Integer&gt;&gt; pr = null;<br />    private Map&lt;Integer, List&lt;Set&lt;Integer&gt;&gt;&gt; ms = null;<br /><br />    private Set&lt;Integer&gt; list2Set(List&lt;Integer&gt; l) &#123;<br />        return l.stream().collect(Collectors.toSet());<br />    &#125;<br /><br />    private Set&lt;Integer&gt; addElement2Set(Set&lt;Integer&gt; s, Integer n)&#123;<br />        return Stream.concat(list2Set(Arrays.asList(n)).stream(), s.stream()).collect(Collectors.toSet());<br />    &#125;<br /><br /><br />    Function&lt;List&lt;Integer&gt;, List&lt;Set&lt;Integer&gt;&gt;&gt; subsetIterator = l -&gt; &#123;<br />        pr = Arrays.asList(list2Set(Arrays.asList()), list2Set(Arrays.asList(l.get(0))));<br /><br />        l.subList(1, l.size()).forEach(n -&gt; &#123;<br />            pr = pr.stream().map(s -&gt; Arrays.asList(s, addElement2Set(s, n))).flatMap(l2 -&gt; l2.stream()).collect(Collectors.toList());<br />        &#125;);<br /><br />        return pr;<br />    &#125;;<br /><br /><br />    static public void main(String[] args)&#123;<br />        ComuteList c = new ComuteList();<br />        List&lt;Set&lt;Integer&gt;&gt; ls = c.subsetIterator.apply(Arrays.asList(1, 2, 3));<br />       System.out.println(ls);<br /><br />    &#125;<br />&#125;</pre>
                <p>While the java code above is bit verbose than its scala counter part, it is not that bad overall. The main code is only 4 lines in both implementations. We need to import some classes and interfaces from java.util package in Java. In scala most packages are imported by default and we don't need to import them explicitly. We have two data members of the class <i class="code-ref-area"> ComuteList </i> <i>pr</i> and <i>ms</i> which we are being referred in our <i class="code-ref-area"> subsetIterator()</i> function. We could have obsorbed them into the <i class="code-ref-area"> subsetIterator()</i> function, like we did in our scala implementation, but we chose to implement <i class="code-ref-area"> subsetIterator()</i> as a function object (java.util.function.Function) with single argument. Core implementation in <i class="code-ref-area"> subsetIterator()</i> is just 4 lines as in our scala implementation with 2 exceptions. We implemented two functions <i class="code-ref-area"> list2Set()</i> and <i class="code-ref-area"> addElement2Set()</i>. We created these functions to use immutable collections (immutable collections are preferrable over mutable collections). In scala, we used the immutable collections from the scala.collections.immutable package.</p>
    </table>


    `
})
export class languageTopicComponent1 {
   
    constructor(private modeService: AppModeService){
        this.modeService.displaySidebar()
    }
}