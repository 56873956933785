import { Component } from '@angular/core'
import { AppModeService } from '../../app-mode.service'

@Component({
    template: `
    <div id="main-content">
    <table class="mission-statement-table">
        <tr>
            <td class="mission-statement-column1">
            </td>  
        </tr>
        <td class="mission-statement-column2">
                <h2><strong>Compute Disjoint Subsets of sizes <i>k</i>, <i>l</i>, and <i>m</i> from a given set</strong></h2>
                <div class="author-date-item">Jagan Lakshmipathy</div>
                <div class="author-date-item">January 6, 2020</div>
                <p>In this problem we will compute all disjoint subsets of sizes <i>k</i>, <i>l</i>, and <i>m</i> for a given set of size <i>n</i> such that <i>n</i> >= <i>k</i> + <i>l</i> + <i>m</i>. </p>
                <pre class="pre-code-area"><pre>class DisjointSubsets&#123;<br />  def generate(list:List[Int], l:Int, m:Int, n:Int)<br />  :Map[Set[Int], Map[Set[Int], List[Set[Int]]]] =<br /><br />  &#123;<br />    list.combinations(l).map(sl1 = &gt; &#123;<br />      Map(sl1.toSet -&gt;<br />        list.filterNot(sl1i = &gt; sl1.contains(sl1i)).combinations(m)<br />        .map(sl2 = &gt; &#123;<br />          Map(sl2.toSet -&gt;<br />            list.filterNot(sl1i = &gt; sl1.contains(sl1i))<br />            .filterNot(sl2i = &gt; sl2.contains(sl2i))<br />          .combinations(n)<br />            .map(lli = &gt; lli.toSet)<br />          .toList<br />          )<br />        &#125;).flatten.toMap<br />      )<br />    &#125;).flatten.toMap<br />  &#125;<br />&#125;<br />object DisjointSubsets&#123;<br />  def main(args:Array[String]) : Unit = &#123;<br />    val d = new DisjointSubsets()<br />    val mls = d.generate(List(1, 2, 3, 4, 5, 6, 7), 2, 2, 2)<br />    println(mls.get(Set(2, 5)).get(Set(1,3)))<br />  &#125;<br />&#125;</pre></pre>  

                
    `
})
export class languageTopicComponent3 {
   
    constructor(private modeService: AppModeService){
        this.modeService.displaySidebar()
    }
}