import { Component } from '@angular/core'
import { LeftSidebar } from '../leftsidebar/leftsidebar.component'
import { AppModeService } from '../../app-mode.service'

@Component({
    template: `
    <div id="main-content">
        <table class="mission-statement-table">
            <tr>
                <td class="mission-statement-column1">
                </td>           
                <td class="mission-statement-column2">
                <h2><strong>Software Tools/Components </strong></h2>
                <p>In this section, you will see software, tools, components, code snippets, articles, experiences, blogs etc. that are relevant to the area of big data and analytics. Some of these concepts and ideas were tested in our lab and documented in the authors first person tone. Please feel free to explore as you wish.</p>
                </td>
                <td class="mission-statement-column3">
                </td>  
            </tr>
        </table>

    </div>
    `
})
export class SoftwareComponent {
   
    constructor(private modeService: AppModeService){
        this.modeService.displaySidebar()
    }
}