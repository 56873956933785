import { Component, OnInit } from '@angular/core'
import { SoftwareComponent } from '../software/software.component'
import { AppModeService } from '../../app-mode.service'

@Component({
    selector :'app-leftsidebar',
    template : `
      <p><a *ngIf="isHidden==false"  href="#">Link</a></p>
      <p><a *ngIf="isHidden==false"  href="#">Link</a></p>
      <p><a *ngIf="isHidden==false"  href="#">Link</a></p>
    `
})

export class LeftSidebar implements OnInit{
  
  showbar(){
    return this.modeService.showSidebar
  }
  constructor(private modeService: AppModeService){
  }

  ngOnInit(){
  }

}