import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { bootstrap } from 'bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './core/header/header.component';
import { HomeComponent } from './core/home/home.component';
import { AboutComponent } from './About/about.component'
import { FooterComponent } from './core/footer/footer.component'
import { LeftSidebar } from './core/leftsidebar/leftsidebar.component';
import { RightSidebar } from './core/rightsidebar/rightsidebar.component';
import { SoftwareComponent } from './core/software/software.component';
import { InstallHadoopAndSparkComponent } from './categories/InstallHadoopAndSpark/installhadoopandspark.component';
import { nnWithRegComponent } from './categories/nnWithReg/nnWithReg.component';
import { scalaComponent} from './categories/Scala/Scala.componenent'
import { linearregComponent} from './categories/scikitlearn/linearreg.componenent'
import { ridgevslassoComponent} from './categories/scikitlearn/ridgevslasso.component'
import { languageTopicComponent1} from './categories/Scala/languagetopic1.component'
import { languageTopicComponent2} from './categories/Scala/languagetopic2.component'
import { languageTopicComponent3} from './categories/Scala/languagetopic3.component'
import { nnWithoutRegComponent } from './categories/nnWithoutReg/nnWithoutReg.component';
import { InstallAndRunKafkaComponent } from './categories/InstallAndRunKafka/installandrunkafka.component'
import { RunWordcountComponent } from './categories/RunHadoopWordcountExample/RunHadoopWordcountExample.component';
import { SparkSqlComponent } from './categories/SparSql/SparkSql.component';
import { StructuredStreamingComponent } from './categories/StructuredStreaming/StructuredStreaming.component'
import { peopleBioComponent } from './People/people.component'



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    AboutComponent,
    FooterComponent,
    LeftSidebar,
    RightSidebar,
    SoftwareComponent,
    InstallHadoopAndSparkComponent,
    nnWithRegComponent,
    scalaComponent,
    linearregComponent,
    ridgevslassoComponent,
    languageTopicComponent1,
    languageTopicComponent2,
    languageTopicComponent3,
    peopleBioComponent,
    nnWithoutRegComponent,
    InstallAndRunKafkaComponent,
    RunWordcountComponent,
    SparkSqlComponent,
    StructuredStreamingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
