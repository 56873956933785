import { Component, OnInit } from '@angular/core';
import { AppModeService } from './app-mode.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'scube';
  showSideBar = true

  constructor(private modeService: AppModeService){

  }

  ngOnInit(){
    this.showSideBar = this.modeService.showSidebar
    console.log("show: " + this.showSideBar)
  }

}
