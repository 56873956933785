import { Component } from '@angular/core'

@Component({
    selector: 'app-footer',
    template : `
    <!-- Footer -->
    <footer id="sticky-footer" class="py-4 bg-light text-blue-50">
    
      <!-- Copyright -->
      <div class="footer-copyright text-center py-3">Asymptoid Analytics Corp.© 2019 Copyright
      </div>
      <!-- Copyright -->
    
    </footer>
    <!-- Footer -->
    `
})
export class FooterComponent {

}
