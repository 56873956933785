import { Component } from '@angular/core'
import { AppModeService } from '../../app-mode.service'

@Component({
    template: `
    <div id="main-content">
        <table class="mission-statement-table">
            <tr>
                <td class="mission-statement-column1">
                </td>           
                <td class="mission-statement-column2">
                <h2><strong>Install and Run Kafka </strong></h2>
                <div class="author-date-item">Jagan Lakshmipathy</div>
                <div class="author-date-item">October 27, 2019</div>
                <p></p>
                <p>First and foremost, the following instructions are meant for mac os system only. However with the exception of the installation step all other steps are applicable for all non-windows based systems (linux or unix based systems). To run Kafka we need zookeeper. So, install zookeeper and kafka in that order as follows: </p>
                <pre>$> brew install zookeeper
$> brew install kafka</pre>
<p>After installing Kafka and zookeeper. set the following in your bash profile. In my case Kafka is installed under /usr/local/Cellar/kafka/2.3.0/</p>
<pre>export KAFKA_HOME=/usr/local/Cellar/kafka/2.3.0/libexec/
export $KAFKA_HOME;$PATH</pre>
                <p>Review the documentation of Kafka as needed <A href="https://kafka.apache.org/documentation/">here</A>. To bring up kafka, open 4 terminals. Bring up zookeeper in terminal 1 as follows:</p>
<pre>$1> zookeeper-server-start /usr/local/Cellar/kafka/2.3.0/libexec/config/zookeeper.properties</pre>
                <p>In terminal 2, bring up kafka server as follows:</p>
<pre>$2> kafka-server-start /usr/local/Cellar/kafka/2.3.0/libexec/config/server.properties</pre>
                <p>In terminal 3, we create a topic  "first_kafka_topic" with appropriate partitions and replication factors. In my case I am running it locally so I chose 1 and 1 respectively. Please note zookeeper instance is listening at port 2181 as we have specified in the properties file when brought up the instance. Once the topic is created we can check to see by listing the topics. Finally we will run the producer to publish to the topic. Note the default port 9092 of the Kafka server.</p>
<pre>$3> kafka-topics --create --zookeeper localhost:2181 --replication-factor 1 --partitions 1 --topic first_kafka_topic
$3> kafka-topics --list --zookeeper localhost:2181
$3> kafka-console-producer --broker-list localhost:9092 --topic first_kafka_topic</pre>
                <p>Finally in terminal 4, bring up kafka consumer as follows. "--from-beginning" option prints messages that were published even before consumer becoming alive.</p>
<pre>$4> kafka-console-consumer --bootstrap-server localhost:9092 --topic first_kafka_topic --from-beginning</pre>
<p>Now that kafka is running and topic "first_kafka_topic" has been created. The following is a sample producer and consumer. This smaple producer will read tweets from twitter and publish to the topic and the consumer will listen to the topic and will do sentiment analysis. Important thing to note here is the dependency package listed below when submitting the spark job "--packages org.apache.spark:spark-sql-kafka-0-10_2.11:2.4.3". Here 2.4.3 should match with the underlying spark version. We can stop the Kafka-console-consumer and run the kafka consumer as shown with the spark-submit below. We will dig in to the details of KafkaProducer and Consumer in our next section.</p>
<pre>$> python3 m03_demo03_kafkaTweetProducer.py localhost 9092 first_kafka_topic "world cup"
$> spark-submit --packages org.apache.spark:spark-sql-kafka-0-10_2.11:2.4.3 \\
    m03_demo04_tweetSentiment.py localhost 9092 \\
    first_kafka_topic</pre>

                </td>
                <td class="mission-statement-column3">
                </td>  
            </tr>
        </table>

    </div>
    `
})
export class InstallAndRunKafkaComponent {
   
    constructor(private modeService: AppModeService){
        this.modeService.displaySidebar()
    }
}