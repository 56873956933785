import { Component } from '@angular/core'
import { AppModeService } from '../../app-mode.service'

@Component({
    template: `
    <div id="main-content">
    <table class="mission-statement-table">
        <tr>
            <td class="mission-statement-column1">
            </td>  
        </tr>
        <td class="mission-statement-column2">
                <h2><strong>Run Hadoop Wordcount Example </strong></h2>
                <div class="author-date-item">Jagan Lakshmipathy</div>
                <div class="author-date-item">October 10, 2019</div>
                <p></p>
                <p>This is a classical hello world for Hadoop. Soon after hadoop cluster has been set up  this <a href="https://github.com/milesegan/scala-hadoop-example"> sample code </a>is typically run as a test. So, let's run this as a test.</p>
                <p>First, clone this code to a local directory. Please see here on how to compile, and run this Scala code in intellij. Before we run this code bring up the Hadoop System as follows:</p>

                <pre>$>cd $HADOOP_HOME
$>./bin/hdfs namenode -format (if you want to start the hdfs on a fresh slate)
$>./sbin/start-dfs.sh (wait until all processes come up)
$>jps (run this command to see hadoop processes are running)
$>./sbin/start-yarn.sh (this will bring up ResourceManager)</pre>
                <p>If you didn't see any error(s) then your single node Hadoop is running., Now lets run the downloaded WordCount example either in IntelliJ or in any of your Scala Dev environment of your choice. As you may have noticed in the code, the program takes two arguments input and output directories. The Code reads the input files from the input directory and writes the count in the out directory in the HDFS. I ran the program to output to /user/jagan/output. So, I isssued the following command to download the output from hdfs to local location to verify the content. </p>
                <pre>$>./bin/hdfs dfs -copyToLocal hdfs://localhost:9000/user/jagan/output /tmp/temp
  Bye     1
  Goodbye 1
  Hadoop  2
  Hello   2
  World   2</pre>
                <p> Wollah!! our first Hadoop example. Remember to clean up the output directory as follows if you don't want to see the "output directory already exists" error if you run this program again.</p>
                <pre>$>./bin/hdfs dfs -rm -R /user/jagan/output</pre>
    </table>
    `
})
export class RunWordcountComponent {
   
    constructor(private modeService: AppModeService){
        this.modeService.displaySidebar()
    }
}